// Sticky play with sound button

const banner = document.querySelector(".feature");
const bannerToggle = document.querySelector(".feature__toggle");
const bannerHeight = banner.offsetTop + 100;
const video = document.querySelector(".feature__video");
const toggleText = document.querySelector(".feature__toggleText");
const toggleIconPlay = document.querySelector(".feature__svgPlay");
const toggleIconMute = document.querySelector(".feature__svgMute");

// class names
const myScrollFunc = function () {
	const y = window.scrollY;
	if (bannerHeight <= y) {
		bannerToggle.className = "feature__toggle hide";
	} else {
		bannerToggle.className = "feature__toggle";
	}
};

const videoScroll = function () {
	const y = window.scrollY;
	if (y >= bannerHeight + 400) {
		video.pause();
	} else {
		video.play();
	}
};

bannerToggle.addEventListener('click', function () {
	bannerToggle.blur();
	video.play();
	videoMedia.pause();
	bannerToggle.classList.toggle("active");
	toggleIconPlay.classList.toggle("hide");
	toggleIconMute.classList.toggle("hide");
	video.muted = !video.muted;
});


// Function to check if video is playing
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function(){
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
})

// video block
const videoItem = document.querySelector('.video__item');
const videoPoster = document.querySelector('.video__poster');
const videoMedia = document.querySelector('.video__media video');

// when clicking on video container
videoItem.addEventListener('click', function () {
	videoPoster.classList.toggle('hide');
	video.pause();

	const videoMessage = document.querySelector('.video__message');


	if (screen.width < 992) {
		if (videoMessage.innerHTML === "Click to Play") {
			videoMessage.innerHTML = "Click to Pause";
		} else {
			videoMessage.innerHTML = "Click to Play";
		}
	}
	else {
		videoMessage.classList.toggle('show');
		videoMessage.innerHTML = "Click to Pause";
	}


	if (videoMedia.playing) {
		videoMedia.pause();
	} else {
		videoMedia.play();
	}
});

// functions
window.addEventListener("scroll", myScrollFunc);
window.addEventListener("scroll", videoScroll);

AOS.init();

